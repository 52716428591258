import { useSelector } from "react-redux";
import { differenceInCalendarDays } from "date-fns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { inverseMap } from "@kikoff/utils/src/object";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoDate,
} from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import { selectHasPaymentMethod } from "./funds";
import { selectFeatureFlag } from "./page";

const initialState = {
  leaseTermByToken: {} as Record<string, web.public_.ILeaseTerm>,
  leaseTerms: null as string[],
  potentialTransactions: null as web.public_.IExternalTransaction[],
  rentHistoryByLeaseTermToken: {} as Record<
    string,
    web.public_.GetReportedRentHistoryResponse.IRentReportingHistory[]
  >,
};

export type RentReportingState = typeof initialState;

const rentReportingSlice = createSlice({
  name: "rentReporting",
  initialState,
  reducers: {
    updateRentHistory(
      state,
      {
        payload,
      }: PayloadAction<RentReportingState["rentHistoryByLeaseTermToken"]>
    ) {
      Object.assign(state.rentHistoryByLeaseTermToken, payload);
    },
    addLeaseTerm(state, { payload }: PayloadAction<web.public_.ILeaseTerm>) {
      state.leaseTerms.push(payload.token);
      state.leaseTermByToken[payload.token] = payload;
    },
    setLeaseTerms(state, { payload }: PayloadAction<web.public_.ILeaseTerm[]>) {
      state.leaseTerms = payload.map(({ token }) => token);
      state.leaseTermByToken = Object.fromEntries(
        payload.map((leaseTerm) => [leaseTerm.token, leaseTerm])
      );
    },
    setPotentialTransactions(
      state,
      { payload }: PayloadAction<RentReportingState["potentialTransactions"]>
    ) {
      state.potentialTransactions = payload;
    },
  },
});

const { actions } = rentReportingSlice;
export const {} = actions;
export default rentReportingSlice.reducer;

export const selectLeaseTerm = createLoadableSelector(
  (token?: string) => (state) =>
    state.rentReporting.leaseTermByToken[
      token || state.rentReporting.leaseTerms?.[0]
    ],
  {
    loadAction: () => fetchLeaseTerms(),
    selectLoaded: () => (state) => state.rentReporting.leaseTerms,
  }
);

export const selectRentHistory = createLoadableSelector(
  (token: string) => (state) =>
    state.rentReporting.rentHistoryByLeaseTermToken[token],
  { loadAction: (token) => fetchRentHistory(token) }
);

export const selectPotentialRentTransactions = createLoadableSelector(
  () => (state: RootState) => state.rentReporting.potentialTransactions,
  {
    loadAction: () => fetchRentTransactions(),
  }
);

export const selectDaysSinceLastRentFound = (token?: string) => (
  state: RootState
) => {
  const leaseTerm = state.rentReporting.leaseTermByToken[token];
  if (!leaseTerm?.rentLastDetectedAt) {
    return null;
  }
  return differenceInCalendarDays(
    Date.now(),
    protoDate(leaseTerm.rentLastDetectedAt)
  );
};

export const selectRentReportingServicingState = createLoadableSelector(
  () => (state): { text: React.ReactNode; url: string; ctaText: string } => {
    const leaseTerm = selectLeaseTerm()(state);
    const manualRentDetection = useSelector(
      selectFeatureFlag("manual_rent_detection")
    );
    const daysSinceLastDetection = useSelector(
      selectDaysSinceLastRentFound(leaseTerm?.token)
    );
    return {
      ctaText: leaseTerm?.agreement.leaseAgreementUploaded
        ? "See more"
        : "Finish setting up",
      ...(() => {
        const hasBank = selectHasPaymentMethod("ACH_BANK_ACCOUNT")(state);

        if (!leaseTerm)
          return {
            text: "Get started",
            url: "/dashboard/rent-reporting/start",
          };

        const { agreement, status } = leaseTerm;
        if (!agreement.leaseStartOn)
          return {
            text: "Add your move-in date",
            url: "/dashboard/rent-reporting/start/move-in",
          };
        if (!agreement.rentAmountCents)
          return {
            text: "Add your rent amount",
            url: "/dashboard/rent-reporting/start/amount",
          };
        if (!hasBank)
          return {
            text: "Link a bank account",
            url: "/dashboard/rent-reporting/start/link-bank",
          };
        if (!agreement.externalBankAccount)
          return {
            text: "Select your rent payment",
            url: "/dashboard/rent-reporting/start/select-payment",
          };

        const { landlordDetails } = agreement;
        if (
          !(
            landlordDetails?.name &&
            landlordDetails?.phone &&
            landlordDetails.type ===
              web.public_.LandlordDetails.LandlordType.INDIVIDUAL
          ) &&
          !(
            landlordDetails?.type ===
              web.public_.LandlordDetails.LandlordType.COMPANY &&
            landlordDetails?.name
          )
        )
          return {
            text: "Add your landlord details",
            url: "/dashboard/rent-reporting/start/landlord-type",
          };
        if (!agreement.leaseAgreementUploaded)
          return {
            text: "Upload your lease",
            url: "/dashboard/rent-reporting/start/lease",
          };

        if (agreement.closedAt)
          return {
            text: "Closed",
            ctaText: "",
            url: "",
          };

        if (status === web.public_.LeaseTerm.Status.VERIFICATION_FAILED)
          return {
            text: "Verification failed",
            url: "/dashboard/rent-reporting/verification",
            ctaText: "Update",
          };

        return {
          text: (() => {
            if (status === web.public_.LeaseTerm.Status.VERIFICATION_PENDING)
              return "Pending";

            const authAction = leaseTerm.agreement.externalBankAccount.actions.find(
              ({ authentication }) => authentication
            )?.authentication;

            if (authAction) return "Reconnect your bank account";

            if (manualRentDetection) {
              if (
                daysSinceLastDetection >
                RentReporting.ManualRentDetection.NoRentDetectedThreshold
              ) {
                return "Pending detection";
              }
              if (
                daysSinceLastDetection <=
                RentReporting.ManualRentDetection.RentDetected
              ) {
                return "Rent detected";
              }
            }

            return "Active";
          })(),
          url: `/dashboard/rent-reporting/${leaseTerm.token}`,
        };
      })(),
    };
  },
  {
    dependsOn: [selectLeaseTerm, [selectHasPaymentMethod, "ACH_BANK_ACCOUNT"]],
  }
);

export const fetchLeaseTerms = () =>
  thunk((dispatch) =>
    webRPC.RentReporting.getLeaseTerms({}).then<web.public_.ILeaseTerm[]>(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setLeaseTerms(data.leaseTerms));
          return data.leaseTerms;
        },
        _DEFAULT: handleFailedStatus("Failed to get rent reporting info."),
      })
    )
  );

// Placeholder for when we add an endpoint for a single invalidation
export const fetchLeaseTerm = (token: string) =>
  thunk((dispatch) =>
    dispatch(fetchLeaseTerms()).then((leaseTerms) =>
      leaseTerms.find((leaseTerm) => leaseTerm.token === token)
    )
  );

export const createLeaseTerm = (address: web.public_.ILeaseAddress) =>
  thunk((dispatch) =>
    webRPC.RentReporting.createLeaseTerm({ address }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.addLeaseTerm(data.leaseTerm));
        },
        _DEFAULT: handleFailedStatus(
          "Failed to begin rent reporting activation."
        ),
      })
    )
  );

export const updateLeaseTerm = (
  token: string,
  updates: Omit<web.public_.IUpdateLeaseTermRequest, "token">
) =>
  thunk((dispatch) =>
    webRPC.RentReporting.updateLeaseTerm({ token, ...updates }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchLeaseTerm(token));
        },
        _DEFAULT: handleFailedStatus("Failed to submit information."),
      })
    )
  );

export const fetchRentTransactions = (manualDetection = false) =>
  thunk<
    Promise<web.public_.IExternalTransaction[] | "NO_EXTERNAL_BANK_ACCOUNTS">
  >((dispatch) =>
    (function poll() {
      return webRPC.RentReporting.getPotentialRentTransactions({
        manualDetection,
      }).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(
              actions.setPotentialTransactions(data.potentialRentTransactions)
            );
            return data.potentialRentTransactions;
          },
          SYNC_IN_PROGRESS: () => promiseDelay(2000).then(poll),
          NO_EXTERNAL_BANK_ACCOUNTS: () => "NO_EXTERNAL_BANK_ACCOUNTS",
          _DEFAULT: handleFailedStatus("Failed to get transactions."),
        })
      );
    })()
  );

export const uploadLeaseDocuments = (leaseTermToken: string, files: File[]) =>
  thunk(async (dispatch) =>
    webRPC.RentReporting.uploadLeaseAgreement({
      leaseTermToken,
      leaseAgreementFiles: await Promise.all(
        files.map(async (file) => ({
          mimeType: file.type,
          fileContent: new Uint8Array(await file.arrayBuffer()),
        }))
      ),
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchLeaseTerm(leaseTermToken));
        },
        _DEFAULT: handleFailedStatus("Failed to upload lease documents."),
      })
    )
  );

export const fetchRentHistory = (leaseTermToken: string) =>
  thunk((dispatch) =>
    webRPC.RentReporting.getReportedRentHistory({ leaseTermToken }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.updateRentHistory({
              [leaseTermToken]: data.rentReportingHistory,
            })
          );
          return data.rentReportingHistory;
        },
        _DEFAULT: handleFailedStatus("Failed to get rent history."),
      })
    )
  );

export const enrollBackreporting = (
  leaseTermToken: string,
  { paymentMethodToken }: { paymentMethodToken: string }
) =>
  thunk((dispatch) =>
    webRPC.RentReporting.enrollBackReporting({
      leaseTermToken,
      paymentMethodToken,
      amountCents: RentReporting.BackReporting.cost,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchLeaseTerm(leaseTermToken));
        },
        _DEFAULT: handleFailedStatus("Failed to enroll in back-reporting."),
      })
    )
  );

export const editLeaseTerm = (
  token: string,
  fields: Omit<web.public_.IEditLeaseTermRequest, "leaseTermToken">
) =>
  thunk((dispatch) =>
    webRPC.RentReporting.editLeaseTerm({
      leaseTermToken: token,
      ...fields,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchLeaseTerm(token));
        },
        _DEFAULT: handleFailedStatus("Failed to update rent amount."),
      })
    )
  );

export const ableToManualSelectRent = (
  rentReportingManualDetectionDismissed: boolean,
  manualRentDetection: boolean,
  daysSinceLastDetection: number,
  leaseTerm: web.public_.ILeaseTerm
) => {
  if (
    rentReportingManualDetectionDismissed ||
    !manualRentDetection ||
    !daysSinceLastDetection ||
    (leaseTerm &&
      leaseTerm.status !==
        web.public_.LeaseTerm.Status.VERIFICATION_SUCCEEDED) ||
    leaseTerm?.agreement?.closedAt
  ) {
    return false;
  }
  return true;
};

export namespace RentReporting {
  export namespace Landlord {
    export const types = ["homeowner", "company"] as const;
    export type Type = typeof types[number];

    export namespace Type {
      export const Enum = web.public_.LandlordDetails.LandlordType;
      export const enumByType = {
        homeowner: Enum.INDIVIDUAL,
        company: Enum.COMPANY,
      } as const;

      export const byEnum = inverseMap(enumByType);
    }
  }
  export namespace BackReporting {
    export const cost = 50_00;
  }
  export namespace ManualRentDetection {
    export const NoRentDetectedThreshold = 30;
    export const RentDetected = 1;
    export const NoRentDetectedPopup = 35;
  }
}

export namespace ExternalTransaction {
  export type Token = string & {};
}
