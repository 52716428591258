import { web } from "../../../protos";

const { Status, Reason, Category } = web.public_.CreditDisputeItem;

export default function createCreditDisputeItem(
  itemId: string,
  status: keyof typeof Status,
  category: keyof typeof Category,
  {
    description = "Disputed item description",
    details = [
      { label: "Account Number", value: itemId },
      { label: "Detail", value: "Value" },
    ],
    title = "Credit Dispute Item Title",
    value = "$500",
    selectedReason = status === "READY_TO_DISPUTE"
      ? Reason.UNKNOWN
      : reasonsByCategory[Category[category]][0],
    possibleReasons = status === "READY_TO_DISPUTE"
      ? reasonsByCategory[Category[category]]
      : null,
  }: Omit<web.public_.ICreditDisputeItem, "itemId" | "status"> = {}
) {
  return web.public_.CreditDisputeItem.create({
    itemId,
    category: Category[category],
    description,
    details,
    status: Status[status],
    title,
    value,
    selectedReason,
    possibleReasons,
    tradelineId: itemId,
  });
}

export const disputeReasonData = {
  [Category.COLLECTION]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this account",
      explanation: "I don't recognize this lender and account.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never opened this account.",
    },
    [Reason.INACCURATE_INFORMATION]: {
      text: "Account information is inaccurate",
      explanation:
        "The account information is being reported inaccurately on my report.",
    },
    [Reason.UNVERIFIED_ACCOUNT]: {
      text: "Unverified account",
      explanation:
        "I never had a contract with this debt collector for any debt.",
    },
    [Reason.INCORRECT_DATE]: {
      text: "The dates on this account are incorrect",
      explanation:
        "The dates listed for this account on my credit report are wrong.",
    },
    [Reason.BALANCE_ERROR]: {
      text: "Account balance and/or past due error",
      explanation: "The balance of this collection is wrong.",
    },
    [Reason.ACCOUNT_PAID]: {
      text: "Paid the original creditor before the collection status",
      explanation:
        "I paid the original creditor in full before this account went into collections.",
    },
    [Reason.PAY_TO_DELETE]: {
      text: "Paid and negotiated a pay-to-delete",
      explanation:
        "I settled this account and negotiated a pay-to-delete agreement with the debt collector.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
    [Reason.PREVIOUSLY_DISPUTED]: {
      text:
        "I previously disputed this, and I don't agree with the investigation.",
      explanation:
        "Please mail me the proof of your investigation or delete the negative information in this account.",
    },
    [Reason.MISSING_OR_INCORRECT_DISPUTE_REMARK]: {
      text: 'The "Account in Dispute" remark is missing or incorrect.',
      explanation:
        "Please update the remark or remove this account from my credit report.",
    },
    [Reason.INCORRECT_HISTORY]: {
      text: "The payment history on this account is incorrect",
      explanation:
        "The payment history listed for this account on my credit report are wrong.",
    },
    [Reason.INCORRECT_TERMS]: {
      text: "The payment terms or account type is incorrect",
      explanation: "This account's payment terms or account type is incorrect.",
    },
    [Reason.TOO_OLD]: {
      text: "Account too old to be on my credit report",
      explanation:
        "It's been 7 years after the date of the first missed payment and therefore, this account should not be on my credit report anymore.",
    },
    // Deprecated
    [Reason.DO_NOT_REMEMBER]: {
      text: "Don't remember being late",
      explanation:
        "I don't remember ever being late with a payment on this account.",
    },
    [Reason.COVID]: {
      text: "Received COVID-19 pandemic accommodation from lender",
      explanation:
        "I am a victim of the coronavirus disease 2019 (COVID–19) pandemic and was granted a late payment accommodation from my lender.",
    },
  },
  [Category.CHARGE_OFF]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this account",
      explanation: "I don't recognize this lender and account.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never opened this account.",
    },
    [Reason.INACCURATE_INFORMATION]: {
      text: "Account information is inaccurate",
      explanation:
        "The account information is being reported inaccurately on my report.",
    },
    [Reason.ACCOUNT_PAID]: {
      text: "Paid the original creditor before the charge-off status",
      explanation:
        "I paid the original creditor in full before this account was charged off.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
    [Reason.PREVIOUSLY_DISPUTED]: {
      text:
        "I previously disputed this, and I don't agree with the investigation.",
      explanation:
        "Please mail me the proof of your investigation or delete the negative information in this account.",
    },
    [Reason.MISSING_OR_INCORRECT_DISPUTE_REMARK]: {
      text: 'The "Account in Dispute" remark is missing or incorrect.',
      explanation:
        "Please update the remark or remove this account from my credit report.",
    },
    [Reason.BALANCE_ERROR]: {
      text: "Account balance and/or past due error",
      explanation: "The balance of this charge-off is wrong.",
    },
    [Reason.INCORRECT_DATE]: {
      text: "The dates on this account are incorrect",
      explanation:
        "The dates listed for this account on my credit report are wrong.",
    },
    [Reason.INCORRECT_HISTORY]: {
      text: "The payment history on this account is incorrect",
      explanation:
        "The payment history listed for this account on my credit report are wrong.",
    },
    [Reason.INCORRECT_TERMS]: {
      text: "The payment terms or account type is incorrect",
      explanation: "This account's payment terms or account type is incorrect.",
    },
    [Reason.TOO_OLD]: {
      text: "Account too old to be on my credit report",
      explanation:
        "It's been 7 years after the date of the first missed payment and therefore, this account should not be on my credit report anymore.",
    },
    // Deprecated
    [Reason.DO_NOT_REMEMBER]: {
      text: "Don't remember being late",
      explanation:
        "I don't remember ever being late with a payment on this account.",
    },
    [Reason.COVID]: {
      text: "Received COVID-19 pandemic accommodation from lender",
      explanation:
        "I am a victim of the coronavirus disease 2019 (COVID–19) pandemic and was granted a late payment accommodation from my lender.",
    },
    [Reason.PAY_TO_DELETE]: {
      text: "Paid and negotiated a pay-to-delete",
      explanation:
        "I settled this account and negotiated a pay-to-delete agreement with the debt collector.",
    },
  },
  [Category.INQUIRY]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this inquiry",
      explanation:
        "I don't recognize this lender and I don't remember authorizing them to perform a hard inquiry on my credit report.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never authorized this inquiry.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
    [Reason.PREVIOUSLY_DISPUTED]: {
      text:
        "I previously disputed this, and I don't agree with the investigation.",
      explanation:
        "Please mail me the proof of your investigation or delete the negative information in this account.",
    },
    [Reason.MISSING_OR_INCORRECT_DISPUTE_REMARK]: {
      text: 'The "Account in Dispute" remark is missing or incorrect.',
      explanation:
        "Please update the remark or remove this account from my credit report.",
    },
    [Reason.INCORRECT_DATE]: {
      text: "The date of this inquiry is incorrect",
      explanation: "The date of this inquiry is wrong.",
    },
    [Reason.TOO_OLD]: {
      text: "Inquiry too old to be on my credit report",
      explanation:
        "It's been 2 years after the date of the inquiry and therefore, this inquiry should not be on my credit report anymore.",
    },
    // Deprecated
    [Reason.UNVERIFIED_ACCOUNT]: {
      text: "Unverified account",
      explanation:
        "I never had a contract with this debt collector for any debt.",
    },
    [Reason.INCORRECT_HISTORY]: {
      text: "The payment history on this account is incorrect",
      explanation:
        "The payment history listed for this account on my credit report are wrong.",
    },
    [Reason.ACCOUNT_PAID]: {
      text: "Paid the original creditor before the charge-off status",
      explanation:
        "I paid the original creditor in full before this account was charged off.",
    },
    [Reason.PAY_TO_DELETE]: {
      text: "Paid and negotiated a pay-to-delete",
      explanation:
        "I settled this account and negotiated a pay-to-delete agreement with the debt collector.",
    },
    [Reason.INCORRECT_TERMS]: {
      text: "The payment terms or account type is incorrect",
      explanation: "This account's payment terms or account type is incorrect.",
    },
    [Reason.DO_NOT_REMEMBER]: {
      text: "Don't remember being late",
      explanation:
        "I don't remember ever being late with a payment on this account.",
    },
    [Reason.COVID]: {
      text: "Received COVID-19 pandemic accommodation from lender",
      explanation:
        "I am a victim of the coronavirus disease 2019 (COVID–19) pandemic and was granted a late payment accommodation from my lender.",
    },
  },
  [Category.LATE_PAYMENT]: {
    [Reason.DO_NOT_REMEMBER]: {
      text: "Don't remember being late",
      explanation:
        "I don't remember ever being late with a payment on this account.",
    },
    [Reason.COVID]: {
      text: "Received COVID-19 pandemic accommodation from lender",
      explanation:
        "I am a victim of the coronavirus disease 2019 (COVID–19) pandemic and was granted a late payment accommodation from my lender.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
    [Reason.PREVIOUSLY_DISPUTED]: {
      text:
        "I previously disputed this, and I don't agree with the investigation.",
      explanation:
        "Please mail me the proof of your investigation or delete the negative information in this account.",
    },
    [Reason.MISSING_OR_INCORRECT_DISPUTE_REMARK]: {
      text: 'The "Account in Dispute" remark is missing or incorrect.',
      explanation:
        "Please update the remark or remove this account from my credit report.",
    },
    [Reason.BALANCE_ERROR]: {
      text: "Account balance and/or past due error",
      explanation: "The balance of this account is wrong.",
    },
    [Reason.INCORRECT_DATE]: {
      text: "The number of days late is incorrect",
      explanation: "The number of days late for this late payment is wrong.",
    },
    [Reason.INCORRECT_TERMS]: {
      text: "The payment terms or account type is incorrect",
      explanation: "This account's payment terms or account type is incorrect.",
    },
    [Reason.TOO_OLD]: {
      text: "Payment too old to be on my credit report",
      explanation:
        "It's been 7 years after the date of the first missed payment and therefore, this account should not be on my credit report anymore.",
    },
    // Deprecated
    [Reason.UNVERIFIED_ACCOUNT]: {
      text: "Unverified account",
      explanation:
        "I never had a contract with this debt collector for any debt.",
    },
    [Reason.ACCOUNT_PAID]: {
      text: "Paid the original creditor before the charge-off status",
      explanation:
        "I paid the original creditor in full before this account was charged off.",
    },
    [Reason.PAY_TO_DELETE]: {
      text: "Paid and negotiated a pay-to-delete",
      explanation:
        "I settled this account and negotiated a pay-to-delete agreement with the debt collector.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never opened this account.",
    },
    [Reason.INACCURATE_INFORMATION]: {
      text: "Account information is inaccurate",
      explanation:
        "The account information is being reported inaccurately on my report.",
    },
  },
};

const reasonsByCategory = Object.fromEntries(
  Object.entries(disputeReasonData).map(([key, value]) => [
    key,
    Object.keys(value).map((key) => +key),
  ])
);
